import { View } from 'react-native';
import makeStyles from 'styles/makeStyles';
import Text from 'components/Text';
import BlinkingBadge from './BlinkingBadge';

const styles = makeStyles((theme) => ({
  main: {
    minWidth: 100,
    paddingRight: theme.spacing(4),
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    whiteSpace: 'nowrap',
  },
}));

const CableBadge = ({ isConnected }) => {
  const status = isConnected ? 'Connected' : 'Connecting ...';

  return (
    <View style={styles.main}>
      <BlinkingBadge isConnected={isConnected} />
      <Text style={styles.label}>{status}</Text>
    </View>
  );
};

export default CableBadge;
