import { useEffect } from 'react';
import dynamic from 'next/dynamic';
import { View, ScrollView } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from 'styles/makeStyles';
import StatusPane from 'components/HomePage/StatusPane';
import NavigationPane from 'components/HomePage/NavigationPane';
import GreetingsPane from 'components/HomePage/GreetingsPane';
import {
  fetchAccountAndSelectDefault,
  fetchOrders,
  selectAuthorized,
  waitlistsFetch,
} from 'slices';

const stylesFn = (theme) => ({
  main: {
    paddingVertical: theme.spacing(13),
    paddingHorizontal: theme.spacing(4),
    backgroundColor: '#FAFAFB',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    ...theme.lgMin({
      paddingHorizontal: theme.spacing(13),
    }),
  },
  topSection: {
    backgroundColor: 'white',
    marginHorizontal: theme.spacing(-13),
    paddingHorizontal: theme.spacing(13),
    marginTop: theme.spacing(-13),
    paddingTop: theme.spacing(13),
    paddingBottom: theme.spacing(6),
  },
});

const OrderEventConsumer = dynamic(
  () => import('components/OrderEventConsumer'),
  { ssr: false }
);

const HomePage = () => {
  const dispatch = useDispatch();
  const styles = useStyles(stylesFn);
  const isAuthorized = useSelector(selectAuthorized);

  useEffect(() => {
    dispatch(fetchAccountAndSelectDefault());
    dispatch(fetchOrders({ withDialog: false, silent: true }));
    dispatch(waitlistsFetch({ past: false }));
  }, []);

  return (
    <ScrollView style={styles.main}>
      <OrderEventConsumer isLogged={isAuthorized} />
      <View style={styles.topSection}>
        <GreetingsPane />
        <StatusPane />
      </View>
      <NavigationPane />
    </ScrollView>
  );
};

export default HomePage;
