import { useEffect } from 'react';
import HomePage from 'components/HomePage';
import Head from 'next/head';
import ProtectedPage from 'components/ProtectedPage';

const INTERCOM_WORKSPACE_ID = process.env.NEXT_PUBLIC_INTERCOM_WORKSPACE_ID;

const INTERCOM_DEFINE_SCRIPT = `
  var APP_ID = '${INTERCOM_WORKSPACE_ID}';

 window.intercomSettings = {
    app_id: APP_ID
  };`;

const HELPSCOUT_SCRIPT_01 = '!function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});';

const HELPSCOUT_SCRIPT_02 = 'window.Beacon(\'init\', \'ace6e05a-27af-4182-943f-b7bbe1a0aa7f\')';

const INTERCOM_WIDGET_SCRIPT = `
(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + APP_ID;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
`;

const Index = (props) => {
  useEffect(() => {
    window.Intercom('boot', window.intercomSettings);
    return () => window.Intercom('shutdown');
  }, []);

  return (
    <>
      <Head>
        <script dangerouslySetInnerHTML={{ __html: INTERCOM_DEFINE_SCRIPT }} />
        <script dangerouslySetInnerHTML={{ __html: INTERCOM_WIDGET_SCRIPT }} />
        <script dangerouslySetInnerHTML={{ __html: HELPSCOUT_SCRIPT_01 }} />
        <script dangerouslySetInnerHTML={{ __html: HELPSCOUT_SCRIPT_02 }} />
      </Head>
      <ProtectedPage>
        <HomePage
          {...props}
        />
      </ProtectedPage>
    </>
  );
};

export default Index;
