import { View } from 'react-native';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { useStyles } from 'styles/makeStyles';
import navigationItems from 'components/HomePage/navigation-items';
import NavigationItem from 'components/HomePage/NavigationItem';
import notification from 'assets/notifications/order.mp3';
import {
  // selectAccountShop,
  selectCurrentLocation,
  selectOrdersActiveCount,
  selectWaitlists,
} from 'slices';

const audio = process.browser ? new Audio(notification) : null;

const stylesFn = (theme) => ({
  main: {
    marginHorizontal: theme.spacing(-4),
    paddingHorizontal: theme.spacing(4),
    paddingVertical: theme.spacing(8),
    flex: 1,
    ...theme.borderTop(1, 'solid', '#DEE0E3'),
    ...theme.lgMin({
      marginHorizontal: theme.spacing(-13),
      paddingHorizontal: theme.spacing(13),
    }),
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(-2),
    ...theme.mdMin({
      display: 'flex',
      flexDirection: 'row',
    }),
  },
});

// const IS_DEV = process.env.NODE_ENV === 'development';

const NavigationPane = () => {
  const styles = useStyles(stylesFn);
  const router = useRouter();
  const activeOrders = useSelector(selectOrdersActiveCount);
  const waitlists = useSelector(selectWaitlists);
  const waitlistsCount = waitlists ? waitlists.length : 0;
  const currentLocation = useSelector(selectCurrentLocation);
  const { waitlistAllowed } = currentLocation || {};
  // const shop = useSelector(selectAccountShop);
  // const { groupGiftCards = [], individualGiftCards = [] } = shop;
  // const giftCards = [...groupGiftCards, ...individualGiftCards];
  // const enabledGiftCards = giftCards.filter((c) => c.enabled);

  const handlePress = (href) => {
    audio.currentTime = 0;
    audio.loop = false;
    audio.play();
    router.push(href);
  };

  const items = navigationItems({
    activeOrders,
    waitingParties: waitlistsCount,
    waitlistAllowed,
    hasEnabledGiftCard: false, // (enabledGiftCards.length > 0 || IS_DEV),
  }).map((nav) => {
    const {
      title, subtitle, backgroundColor, accentColor, href, Icon, disabled,
    } = nav;
    return (
      <NavigationItem
        Icon={Icon}
        accentColor={accentColor}
        backgroundColor={backgroundColor}
        disabled={disabled}
        onPress={() => handlePress(href)}
        subtitle={subtitle}
        title={title}
      />
    );
  });

  return (
    <View style={styles.main}>
      <View style={styles.row}>
        {items}
      </View>
    </View>
  );
};

export default NavigationPane;
