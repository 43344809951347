import Text from 'components/Text';
import ChatButton from 'components/HomePage/ChatButton';

const buildPrinterStatus = ({ printers, subtitleStyle, offlineStyle }) => {
  const printerTotal = printers.length;
  if (printerTotal) {
    const printerErrors = printers.reduce((prev, curr) => {
      const { lastApiStatus } = curr;
      if (lastApiStatus === 'ERROR') {
        return prev + 1;
      }
      return prev;
    }, 0);

    const offlineVerb = printerErrors > 1 ? 'are' : 'is';
    const onlineVerb = printerTotal > 1 ? 'are' : 'is';

    const printerSubtitle = printerErrors ? `${printerErrors} of ${printerTotal} ${offlineVerb} offline` : `${printerTotal} of ${printerTotal} ${onlineVerb} online`;
    return { title: 'Printers', subtitle: <Text style={[subtitleStyle, offlineStyle]}>{printerSubtitle}</Text> };
  }

  return { title: 'Printers', subtitle: <Text style={subtitleStyle}>Not configured</Text> };
};

const items = ({
  location = {}, printers = [], cableBadge, subtitleStyle, offlineStyle,
}) => {
  const printerStatus = buildPrinterStatus({ printers, subtitleStyle, offlineStyle });

  return [
    { title: 'Location', subtitle: <Text style={subtitleStyle}>{location.name}</Text> },
    { title: 'Connection status', subtitle: cableBadge },
    printerStatus,
    { title: 'Support', subtitle: <ChatButton subtitleStyle={subtitleStyle} /> },
  ];
};

export default items;
