import { View } from 'react-native';
import Greeting from 'greeting-time';
import { useDispatch } from 'react-redux';
import { useStyles } from 'styles/makeStyles';
import Text from 'components/Text';
import Button from 'components/Button';
import { setAuthorized } from 'slices';

const stylesFn = (/* theme */) => ({
  main: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    // paddingVertical: theme.spacing(2),
    justifyContent: 'space-between',
    width: '100%',
  },
  greeting: {
    fontSize: 32,
    lineHeight: 56,
    fontWeight: 700,
  },
});

const GreetingsPane = () => {
  const styles = useStyles(stylesFn);
  const dispatch = useDispatch();
  const greeting = `${Greeting(new Date())}`;

  const handleSignout = () => {
    dispatch(setAuthorized(false));
  };

  return (
    <View style={styles.main}>
      <Text style={styles.greeting}>
        {greeting}
        .
      </Text>
      <Button
        variant="link"
        onPress={handleSignout}
      >
        Sign out
      </Button>
    </View>
  );
};

export default GreetingsPane;
