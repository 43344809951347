import { View } from 'react-native';
import { useSelector } from 'react-redux';
import { useStyles } from 'styles/makeStyles';
import Text from 'components/Text';
import statusItems from 'components/HomePage/status-items';
import CableBadge from 'components/CableBadge';
import {
  selectCurrentLocation,
  selectIsConnected,
  selectAccount,
} from 'slices';

const stylesFn = (theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  box: {
    marginVertical: theme.spacing(2),
    flex: '1 1 200px',
  },
  boxTitle: {
    lineHeight: 32,
    fontWeight: 700,
    fontSize: theme.fontSizeLg,
    color: theme.textDisabledColor,
  },
  boxSubtitle: {
    lineHeight: 24,
  },
  connectionRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  offline: {
    color: theme.errorColor,
  },
});

const StatusPane = () => {
  const styles = useStyles(stylesFn);
  const account = useSelector(selectAccount);
  const location = useSelector(selectCurrentLocation);
  const isConnected = useSelector(selectIsConnected);
  const { shop = {} } = account;
  const { cloudPrinters = [] } = shop;

  const locPrinters = cloudPrinters.filter((p) => location && (!p.location || p.location === `${location.id}`));

  const cableBadge = <CableBadge isConnected={isConnected} />;

  const items = statusItems({
    cableBadge,
    isConnected,
    location,
    printers: locPrinters,
    subtitleStyle: styles.boxSubtitle,
    offlineStyle: styles.offline,
  }).map((i) => {
    const { title, subtitle } = i;
    return (
      <View
        key={title}
        style={styles.box}
      >
        <Text style={styles.boxTitle}>{title}</Text>
        {subtitle}
      </View>
    );
  });

  return (
    <View style={styles.main}>
      {items}
    </View>
  );
};

export default StatusPane;
