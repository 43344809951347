import LightningIcon from 'assets/lightning-page.svg';
import WaitlistIcon from 'assets/waitlist-page.svg';
import GiftcardIcon from 'assets/giftcard-page.svg';

const navigationItems = ({
  activeOrders,
  waitingParties,
  waitlistAllowed,
  hasEnabledGiftCard,
}) => ([{
  Icon: LightningIcon,
  accentColor: '#FDCA64',
  backgroundColor: '#FBEAC9',
  disabled: false,
  href: '/lightning',
  subtitle: `${activeOrders} active orders`,
  title: 'Order management',
}, {
  Icon: WaitlistIcon,
  accentColor: '#BAD1D7',
  backgroundColor: '#E0EAED',
  disabled: !waitlistAllowed,
  href: '/waitlist',
  subtitle: `${waitingParties} waiting parties`,
  title: 'Waitlist management',
}, {
  Icon: GiftcardIcon,
  accentColor: '#FFA8AB',
  backgroundColor: '#FCD9DB',
  disabled: !hasEnabledGiftCard,
  href: '/giftcarts',
  subtitle: 'Check balances and redeem',
  title: 'Gift cards',
}]);

export default navigationItems;
