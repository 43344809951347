import { useEffect, useRef } from 'react';
import { Animated } from 'react-native';
import makeStyles from 'styles/makeStyles';

const styles = makeStyles((theme) => ({
  badge: {
    width: 6,
    height: 6,
    borderRadius: 6,
    backgroundColor: 'green',
    marginRight: theme.spacing(2),
  },
  red: {
    backgroundColor: theme.errorColor,
  },
  green: {
    backgroundColor: theme.successColor,
  },
}));

const BlinkingBadge = ({ isConnected }) => {
  const fadeAnim = useRef(new Animated.Value(0)).current;

  const fadeIn = () => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 100,
      delay: 50,
    }).start((evt) => {
      const { finished } = evt;
      if (finished) {
        // eslint-disable-next-line no-use-before-define
        fadeOut();
      }
    });
  };

  const fadeOut = () => {
    if (isConnected) {
      return;
    }
    Animated.timing(fadeAnim, {
      toValue: 0,
      duration: 100,
      delay: 100,
    }).start((evt) => {
      const { finished } = evt;
      if (finished) {
        fadeIn();
      }
    });
  };

  useEffect(() => {
    fadeIn();
  }, [isConnected]);

  const bgStyle = isConnected ? styles.green : styles.red;

  return (
    <Animated.View style={[styles.badge, bgStyle, { opacity: fadeAnim }]} />
  );
};

export default BlinkingBadge;
