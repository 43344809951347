import { View, TouchableOpacity } from 'react-native';
import { useStyles } from 'styles/makeStyles';
import Text from 'components/Text';

const stylesFn = (theme) => ({
  main: {
    flex: 1,
    padding: theme.spacing(6),
    margin: theme.spacing(2),
    borderRadius: 6,
    cursor: 'pointer',
  },
  medal: {
    height: theme.spacing(22),
    width: theme.spacing(22),
    borderRadius: '50%',
    marginBottom: theme.spacing(5),
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  medalBody: {
    backgroundColor: 'black',
    height: theme.spacing(14),
    width: theme.spacing(14),
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: 24,
    lineHeight: 36,
    color: '#000',
  },
  subtitle: {
    color: '#4C4128',
  },

});

const NavigationItem = ({
  Icon,
  accentColor,
  backgroundColor,
  disabled,
  onPress,
  subtitle,
  title,
}) => {
  const styles = useStyles(stylesFn);

  if (disabled) {
    return null;
  }

  return (
    <TouchableOpacity onPress={onPress} style={[styles.main, { backgroundColor }]}>
      <View style={[styles.medal, { backgroundColor: accentColor }]}>
        <View style={styles.medalBody}>
          <Icon width="24" height="24" />
        </View>
      </View>
      <Text style={styles.title}>{title}</Text>
      <Text style={styles.subtitle}>{subtitle}</Text>
    </TouchableOpacity>
  );
};

export default NavigationItem;
