import { View, TouchableOpacity } from 'react-native';
import Text from 'components/Text';
import ExternalResource from 'assets/external-resource.svg';

const HREF = 'https://intercom.help/sociavore/en/articles/6307001-how-to-set-up-the-lightning-dashboard';

const ChatButton = ({ subtitleStyle }) => {
  const handlePress = () => {
    window.open(HREF, '_blank');
  };

  return (
    <TouchableOpacity onPress={handlePress}>
      <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Text style={subtitleStyle}>Documentation and live chat</Text>
        <ExternalResource style={{ marginLeft: 4 }} />
      </View>
    </TouchableOpacity>
  );
};

export default ChatButton;
